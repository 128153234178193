import React from 'react';
import classNames from 'classnames';
import ProLink from '../../shared/ProLink/ProLink';
import Slider from 'react-slick';

import { isClient } from '../../../helpers';
import './BlockLinks.scss';
import AccessibleCarouselArrow from '../../shared/AccessibleCarouselButton/AccessibleCarouselButton';
import CustomPaging from '../../shared/CustomPaging/CustomPaging';
class BlockLinks extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        /**
         * standard props used in the style / function of the module.
         * Null if not specified
         **/

        const data = this.props.data || {};
        const previousModule = this.props.previousModule;
        const logicalName = data.logicalName || null;

        const title = data.title || null;

        const alignment = data.alignment || null;

        const backgroundColor = data.backgroundColor || null;

        const isSlider = data.isSlider;

        const inlineStyles = {
            backgroundColor: backgroundColor,
        };

        /**
         * props specific to this module
         **/
        const blocks = data.blocks || [];

        const moduleClasses = classNames({
            'container-fluid': true,
            'block-links': true,
            'block-links-slider': isSlider,
            'module-after-homehero': isSlider || previousModule === 'HomepageHero',
            initialised: isClient(),
        });

        const titleClasses = classNames({
            'col-md-12': true,
            'text-left': alignment === 'left',
            'text-center': alignment === 'center',
            'text-right': alignment === 'right',
        });

        const blockMarkupClasses = classNames({
            container: true,
            'block-links-wrapper': true,
        });

        const sliderSettings = {
            arrows: blocks.length > 1,
            dots: true,
            infinite: false,
            prevArrow: <AccessibleCarouselArrow />,
            nextArrow: <AccessibleCarouselArrow />,
            customPaging: CustomPaging,
            speed: 500,
            slidesToShow: previousModule === 'HomepageHero' ? 3 : 4,
            slidesToScroll: previousModule === 'HomepageHero' ? 3 : 4,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        arrows: false,
                        dots: blocks.length > 1,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        dots: blocks.length > 1,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: false,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: false,
                        dots: blocks.length > 1,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: false,
                    },
                },
            ],
        };

        let blockMarkup;

        if (blocks.length > 0) {
            blockMarkup = blocks.map((block, i) => {
                const blockClass = classNames({
                    'block-link-block': true,
                    'block-link-first-slider': i === 0 && isSlider,
                    'block-link-slide': isSlider,
                    'col-md-12': blocks.length <= 1 && !isSlider,
                    'col-md-6': blocks.length === 2 && !isSlider,
                    'col-md-4': blocks.length === 3 && !isSlider,
                    'col-md-3': blocks.length >= 4 && !isSlider,
                });

                return (
                    <div key={i} className={blockClass}>
                        <ProLink
                            to={block.link}
                            target={block.target === 'blank' ? '_blank' : null}
                            className="block-link block-link-inner"
                            /* removed as is was causing issues on non-HeroBanner blocks */
                            // style={
                            //   {
                            //     // height: '100%'
                            //   }
                            // }
                        >
                            <div
                                className="background-image"
                                style={{
                                    backgroundImage: block.backgroundImage
                                        ? 'url(' + block.backgroundImage + ')'
                                        : null,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'top',
                                    overflow: 'auto',
                                    height: '100%',
                                }}
                            ></div>
                            <div
                                style={{ backgroundColor: block.backgroundColor }}
                                className={classNames({
                                    'block-link-text': true,
                                    'block-link-top': block.textPosition === 'top',
                                    'block-link-bottom': block.textPosition === 'bottom',
                                })}
                            >
                                <div>
                                    <p className="block-link-title" style={{ color: block.titleColor }}>
                                        {block.title}
                                    </p>
                                    <p className="block-link-subtitle h3" style={{ color: block.textColor }}>
                                        {block.intro}
                                    </p>
                                </div>
                            </div>
                        </ProLink>
                    </div>
                );
            });
        } else {
            blockMarkup = null;
        }

        let blockMarkupWrapper;

        if (isSlider) {
            blockMarkupWrapper = (
                <div className={blockMarkupClasses} style={inlineStyles}>
                    <Slider {...sliderSettings}>{blockMarkup}</Slider>
                </div>
            );
        } else {
            blockMarkupWrapper = (
                <div className={blockMarkupClasses}>
                    <div className="row tw-mb-5">{blockMarkup}</div>
                </div>
            );
        }

        return (
            <div className={moduleClasses} id={logicalName} style={inlineStyles}>
                {title && (
                    <div className="container block-links-title">
                        <div className={titleClasses}>
                            <h2>{title}</h2>
                        </div>
                    </div>
                )}
                {blockMarkupWrapper}
            </div>
        );
    }
}

export default BlockLinks;
